import React, { useState, useEffect } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { Container } from "react-bootstrap"
import Slide from "../components/utility/slide_content"
import Frame from "../components/common/frame"
import "../styles/pages/supercon-2022.scss"
import Carousal from "../components/common/carousel-new"
import Favicon from "../assets/images/favicon-new.png"
import Close from "../assets/images/close-white.png"
import PlayIcon from "../assets/images/home/play-button-white.svg"
import CTABox from "../components/common/cta-box"
import SocialShare from "../components/social_share"
import SuperCon from "../pages/jan-2022"
import parse from "html-react-parser"
import PDFIcon from "./../assets/images/pdf-icon.svg"
// import HelloBarSuper from "../components/hellobarSuperSummit"

const SuperSummitVideo = ({ pageContext }) => {
  const { webinar } = pageContext
  const [url, setUrl] = useState("")
  // const shareTags = [webinar.heroHeading.replaceAll(" ","_").toLowerCase(), webinar.heroSubheading.replaceAll(" ","_").toLowerCase()]
  const shareTags = []
  useEffect(() => {
    setUrl(window.location.href)
  }, [url])

  const contentTemplate = props => {
    let { i, data } = props
    return (
      <div key={i} className="Speaker-card">
        <Link to={`/jan-2022/${data.slug}`}>
          <p className="fixed-title">TITLE</p>
          <span className="p18">
            <p className="title">{data.speakerBio}</p>
          </span>
          <div className="position-relative">
            <div className="overflow-hidden">
              <img
                className="speaker-img"
                style={{ width: "100%" }}
                src={data.speakerImage.url}
                alt=""
              />
            </div>
            <span className="play-btn">
              <img className="play-icn" src={PlayIcon} alt="" />
            </span>
          </div>

          <div className="name-container">
            <span className="fixed-title" style={{ lineHeight: "30px" }}>
              SPEAKER
            </span>
            <span className="p16">
              <p className="name">{data.speakerName}</p>
            </span>
          </div>
        </Link>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SuperSummitEventPage {
          site {
            siteMetadata {
              url
              twitterHandle
            }
          }
          SuperOps {
            webinars(where: { pageName: SuperCon }) {
              heroHeading
              heroSubheading
              speakerName
              speakerBio
              speakerImage {
                url(transformation: { document: { output: { format: webp } } })
              }
              time
              videoLink
              downloadFile {
                url
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {[data.site].map((el, idx) => {
            const twitterHandle = el.siteMetadata.twitterHandle

            return (
              <div className="SuperCon event video">
                <Frame
                  bodyClassName="SuperCon-body"
                  title={webinar.seo?.title}
                  description={webinar.seo?.description}
                  keywords={webinar.seo?.keywords}
                  ogTitle={webinar.seo?.title}
                  ogDescription={webinar.seo?.description}
                  ogImage={webinar.seo?.image}
                  ogType="website"
                  IsFooterVisble="No"
                  favIcon={Favicon}
                >
                  <div className="overlay">
                    <Container>
                      <Link href="/jan-2022">
                        <img src={Close} className="close-icn" alt="close" />
                      </Link>
                      <div className="p14">
                        <p className="active"> {webinar.heroHeading}</p>
                      </div>
                      <h3>{webinar.speakerBio}</h3>
                      <Slide delay={"0.2s"}>
                        <div className="mx-auto">
                          <div className="position-relative">
                            {/* <div className="overlay-video">
                                <iframe
                                  src={`${webinar.videoLink}?rel=0`}
                                  frameborder="0"
                                  allowfullscreen="allowfullscreen"
                                  allow="accelerometewatchr; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                />
                              </div> */}
                            <div className="iframe-wrapper">
                              <iframe
                                src={`${webinar.videoLink}?rel=0`}
                                frameborder="0"
                                allowfullscreen="allowfullscreen"
                                allow="accelerometewatchr; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              />
                            </div>
                          </div>
                        </div>
                      </Slide>
                      <Slide delay={"0.2s"}>
                        <div className="p14">
                          <p className="active mb-1">share it on</p>
                        </div>

                        <SocialShare
                          socialConfig={{
                            twitterHandle,
                            config: {
                              url: url,
                              title: webinar.speakerBio,
                            },
                          }}
                          tags={shareTags}
                          direction="vrt"
                        />
                      </Slide>
                      {webinar?.block1?.html && (
                        <section className="about-guest">
                          <div className="p16">
                            {parse(webinar.block1.html)}
                          </div>
                        </section>
                      )}
                      {webinar?.downloadFile?.url && (
                        <>
                          <div className="download-file">
                            <p>Get a one page summary of this talk</p>
                            <span className="text">
                              <img
                                className="pdf-icon"
                                src={PDFIcon}
                                alt="icon"
                                width="24"
                              />
                              <a
                                href={`${webinar?.downloadFile?.url}`}
                                download
                                target="_blank"
                              >
                                Download the PDF here
                              </a>
                            </span>
                          </div>
                        </>
                      )}
                      <Slide delay={"0.2s"}>
                        <div className="connect-box">
                          <CTABox
                            theme="custom"
                            bgColor="#141313"
                            heading="Discuss with fellow MSPs like you"
                            description="Join our Slack community to meet, network, or just hang out."
                            cta1Type="link"
                            cta1Link="https://join.slack.com/t/superconnectgroup/shared_invite/zt-12meesfo0-WYfAEnJ9~I82ahsyYqyoZg"
                            cta1Text="CONNECT NOW"
                            buttonColor="primary pink"
                            newTab
                          />
                        </div>
                      </Slide>

                      {webinar.relatedVideo.length > 0 && (
                        <Slide delay={"0.2s"}>
                          <div className="related-videos">
                            <div className="mx-auto">
                              <div className="p14">
                                <p className="active">related videos</p>
                              </div>
                              <div className="Speakers-List">
                                <div className="v-mobile">
                                  <Carousal
                                    template={contentTemplate}
                                    content={webinar.relatedVideo}
                                  />
                                </div>

                                <div className="speaker-cards v-desk-flex">
                                  {webinar.relatedVideo.map((cont, i) => {
                                    let Template = contentTemplate
                                    return (
                                      <Template key={i} i={i} data={cont} />
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Slide>
                      )}
                    </Container>
                  </div>
                  <div className="bg-page">
                    <SuperCon popupNotVisible />
                  </div>
                </Frame>
              </div>
            )
          })}
        </>
      )}
    />
  )
}

export default SuperSummitVideo
