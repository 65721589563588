import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share"
import iconFacebook from "../assets/images/icon-facebook.svg"
import iconLinkedin from "../assets/images/icon-linkedin.svg"
import iconReddit from "../assets/images/icon-reddit.svg"
import iconRSS from "../assets/images/icon-rss.svg"
import iconTwitter from "../assets/images/icon-twitter.svg"
import "../styles/social-share.scss"
import SVGIcon from "../components/common/SVGIcon"
const socialShare = ({ socialConfig, tags, direction, page }) => (
  <>
    {direction === "hor" ? (
      <>
        <div className="post-social hor">
          <TwitterShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded twitter"
            title={socialConfig.config.title}
            via={socialConfig.twitterHandle.split("@").join("")}
            hashtags={tags}
          >
            {/* <img
              src={iconTwitter}
              alt="Twitter"
              className="objcvr"
              width="16"
              height="16"
            /> */}
            <SVGIcon name="social/icontwitter"
              className="objcvr"
              width="16"
              height="16"
            />
          </TwitterShareButton>
          <RedditShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded reddit"
            title={socialConfig.config.title}
          >
            {/* <img
              src={iconReddit}
              alt="Reddit"
              className="objcvr"
              width="16"
              height="16"
            /> */}
            <SVGIcon name="social/iconreddit"
              className="objcvr"
              width="16"
              height="16"
            />
          </RedditShareButton>
          <LinkedinShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded linkedin"
            title={socialConfig.config.title}
          >
            {/* <img
              src={iconLinkedin}
              alt="LinkedIn"
              className="objcvr"
              width="16"
              height="16"
            /> */}
            <SVGIcon name="social/iconlinkedin"
              className="objcvr"
              width="16"
              height="16"
            />
          </LinkedinShareButton>
          <FacebookShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded facebook"
            quote={socialConfig.config.title}
          >
            {/* <img
              src={iconFacebook}
              alt="Facebook"
              className="objcvr"
              width="8"
              height="16"
            /> */}
            <SVGIcon name="social/iconfacebook"
              className="objcvr"
              width="16"
              height="16"
            />
          </FacebookShareButton>

          {page === "blog" && (
            <Link href="/blog/feed.rss" target="_blank">
              {/* <img
                src={iconRSS}
                alt="RSS Feed"
                className="objcvr"
                width="8"
                height="16"
              /> */}
              <SVGIcon name="social/iconrss"
              className="objcvr"
              width="16"
              height="16"
              />
            </Link>
          )}
        </div>
      </>
    ) : (
      <>
        <div className="post-social vrt">
          <TwitterShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded twitter"
            title={socialConfig.config.title}
            via={socialConfig.twitterHandle.split("@").join("")}
            hashtags={tags}
          >
            {/* <img
              src={iconTwitter}
              alt="Twitter"
              className="objcvr"
              width="16"
              height="16"
            /> */}
            <SVGIcon name="social/icontwitter"
              className="objcvr"
              width="16"
              height="16"
            />
          </TwitterShareButton>
          <RedditShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded reddit"
            title={socialConfig.config.title}
          >
            {/* <img
              src={iconReddit}
              alt="Reddit"
              className="objcvr"
              width="16"
              height="16"
            /> */}
            <SVGIcon name="social/iconreddit"
              className="objcvr"
              width="16"
              height="16"
            />
          </RedditShareButton>
          <LinkedinShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded linkedin"
            title={socialConfig.config.title}
          >
            {/* <img
              src={iconLinkedin}
              alt="LinkedIn"
              className="objcvr"
              width="16"
              height="16"
            /> */}
            <SVGIcon name="social/iconlinkedin"
              className="objcvr"
              width="16"
              height="16"
            />
          </LinkedinShareButton>
          <FacebookShareButton
            url={socialConfig.config.url}
            className="button is-outlined is-rounded facebook"
            quote={socialConfig.config.title}
          >
            {/* <img
              src={iconFacebook}
              alt="Facebook"
              className="objcvr"
              width="8"
              height="16"
            /> */}
            <SVGIcon name="social/iconfacebook"
              className="objcvr"
              width="16"
              height="16"
            />
          </FacebookShareButton>
          {page === "blog" && (
            <Link href="/blog/feed.rss" target="_blank" className="button">
              {/* <img
                src={iconRSS}
                alt="RSS Feed"
                className="objcvr"
                width="8"
                height="16"
              /> */}
              <SVGIcon name="social/iconrss"
              className="objcvr"
              width="16"
              height="16"
              />
            </Link>
          )}
        </div>
      </>
    )}
  </>
)

socialShare.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
socialShare.defaultProps = {
  tags: [],
}

export default socialShare
