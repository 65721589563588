import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import PatternCTABoxDark from "../../components/common/pattern-cta-box-dark"
import PatternCTABoxLight from "../../components/common/pattern-cta-box-light"
import DOMPurify from "dompurify"
import { useLocation } from "@reach/router"
import Buttons from "./button"
import { checkValidEmail } from "./../utility/validation"

function CTABox(props) {
  const currentLocation = useLocation()

  const [dsEmail, setDSEmail] = useState("")

  const [dsEmailError, setDSEmailError] = useState("")
  const [dsEmailErrorPop, setDSEmailErrorPop] = useState("none")

  const [isInvalidDSEmail, setIsInvalidDSEmail] = useState(false)

  useEffect(() => {
    if (dsEmail.length > 0 && checkValidEmail(dsEmail) && !isInvalidDSEmail) {
      setDSEmailError("1px solid #190429")
      setDSEmailErrorPop("none")
    }

    if (isInvalidDSEmail) {
      setDSEmailError("1px solid #ff0055")
      setDSEmailErrorPop("block")

      var ErrorTimer = setTimeout(function() {
        setIsInvalidDSEmail(false)
        setDSEmailError("1px solid #190429")
        setDSEmailErrorPop("none")
      }, 5000)
    }

    return () => clearTimeout(ErrorTimer)
  }, [dsEmail, isInvalidDSEmail])

  const handleDSSubmit = evt => {
    evt.preventDefault()

    if (dsEmail === "" || !checkValidEmail(dsEmail) || isInvalidDSEmail) {
      setDSEmailError("1px solid #ff0055")
      setDSEmailErrorPop("block")

      setTimeout(function() {
        setDSEmailError("1px solid #190429")
        setDSEmailErrorPop("none")
      }, 5000)
    } else {
      setDSEmailError("1px solid #190429")
      setDSEmailErrorPop("none")
    }

    if (dsEmail !== "" && checkValidEmail(dsEmail)) {
      var xhr = new XMLHttpRequest()
      var url = process.env.HUBSPOT_SUBSCRIBE_API_ENDPOINT
      var data = {
        submittedAt: Date.now(),
        fields: [
          {
            name: "email",
            value: DOMPurify.sanitize(dsEmail),
          },
        ],
        context: {
          pageUri: process.env.HUBSPOT_SUBSCRIBE_API_URL,
          pageName: "Subscription - Superops.ai",
        },
      }

      var final_data = JSON.stringify(data)

      xhr.open("POST", url)

      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          window.location.href = `/thank-you?redirect=${currentLocation.pathname}`
          setIsInvalidDSEmail(false)
        } else if (
          xhr.readyState === 4 &&
          (xhr.status === 400 || xhr.status === 403 || xhr.status === 404)
        ) {
          setIsInvalidDSEmail(true)
        }
      }

      xhr.send(final_data)
    }
  }

  return (
    <>
      {props.theme === "dark" && (
        <div className={`cta-box ${props.theme}`}>
          <Container
            className="position-relative "
            style={{
              maxWidth: props.maxBoxWidth ? props.maxBoxWidth : "1104px",
            }}
          >
            <PatternCTABoxDark />
            <h4
              className="position-relative"
              style={{ maxWidth: props.maxHeadingWidth }}
            >
              {" "}
              {props.heading}{" "}
            </h4>

            <span className="p16">
              <p style={{ maxWidth: props.maxDescriptionWidth }}>
                {" "}
                {props.description}{" "}
              </p>
            </span>

            {!!props.cta1Text && (
              <>
                {props.cta1Type === "link" && (
                  <>
                    <Buttons
                      theme="primary white"
                      arrow
                      link={`/${props.cta1Link}`}
                      text={props.cta1Text}
                    />

                    <Buttons
                      theme="secondary white"
                      arrow
                      link="/demo"
                      text="BOOK A DEMO"
                    />
                  </>
                )}
                {props.cta1Type !== "link" && (
                  <>
                    <Buttons
                      theme="primary white"
                      arrow
                      onClick={props.cta1Link}
                      text={props.cta1Text}
                    />

                    <Buttons
                      theme="secondary white"
                      arrow
                      link="/demo"
                      text="BOOK A DEMO"
                    />
                  </>
                )}
              </>
            )}

            {!!props.cta2Text && (
              <>
                {props.cta2Type === "link" && (
                  <Buttons
                    theme="primary white"
                    arrow
                    link={`/${props.cta2Link}`}
                    text={props.cta2Text}
                  />
                )}
                {props.cta2Type !== "link" && (
                  <Buttons
                    theme="primary white"
                    arrow
                    onClick={props.cta2Link}
                    text={props.cta2Text}
                  />
                )}
              </>
            )}
          </Container>
        </div>
      )}

      {props.theme === "light" && (
        <div className={`cta-box ${props.theme}`}>
          <Container className="position-relative">
            <PatternCTABoxLight />
            <Row>
              <Col lg={8}>
                <h4
                  style={{ maxWidth: props.maxHeadingWidth, margin: "0 auto" }}
                  className="position-relative"
                >
                  {" "}
                  {props.heading}{" "}
                </h4>
              </Col>
              <Col lg={4} className="align">
                {props.cta1Type === "link" && (
                  <Buttons
                    theme="primary"
                    arrow
                    link={`/${props.cta1Link}`}
                    text={props.cta1Text}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {props.theme === "custom" && (
        <div className={`cta-box light ${props.theme}`}>
          <Container
            className="position-relative"
            style={{ background: props.bgColor }}
          >
            {props.isPatternVisible && <PatternCTABoxLight />}

            <h4
              className="position-relative"
              style={{ maxWidth: props.maxHeadingWidth }}
            >
              {" "}
              {props.heading}{" "}
            </h4>

            <span className="p16">
              <p style={{ maxWidth: props.maxDescriptionWidth }}>
                {" "}
                {props.description}{" "}
              </p>
            </span>

            {props.cta1Type === "link" && (
              <Buttons
                theme={props.buttonColor}
                arrow
                link={`${props.cta1Link}`}
                text={props.cta1Text}
                newTab={props.newTab}
              />
            )}

            {props.cta1Type === "download" && (
              <a
                href={props.cta1Link}
                download
                target={props.newTab ? "_blank" : "_self"}
              >
                <Buttons
                  theme={props.buttonColor}
                  arrow
                  text={props.cta1Text}
                />
              </a>
            )}
          </Container>
        </div>
      )}

      {props.theme === "dark-subscribe" && (
        <div className={`cta-box ${props.theme}`}>
          <Container className="position-relative">
            <PatternCTABoxLight />
            <Row>
              <Col lg={6}>
                <h4 className="position-relative"> {props.heading} </h4>
              </Col>
              <Col lg={6} className="align inline-form">
                <form onSubmit={handleDSSubmit}>
                  <div className="position-relative">
                    <input
                      type="text"
                      name="EMAIL"
                      className="email"
                      value={dsEmail}
                      placeholder="Email"
                      style={{ border: dsEmailError }}
                      onChange={e => setDSEmail(e.target.value)}
                    />
                    <div
                      className="error-pop position-absolute p13"
                      style={{ display: dsEmailErrorPop }}
                    >
                      <p>Please enter a valid email.</p>
                    </div>
                    <Buttons theme="primary white" text="SUBSCRIBE" />
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {props.theme === "dark-plain" && (
        <div className={`cta-box ${props.theme}`}>
          <Container className="position-relative">
            <h4
              className="position-relative"
              style={{ maxWidth: props.maxHeadingWidth }}
            >
              {" "}
              {props.heading}{" "}
            </h4>

            {props.description && (
              <span className="p16">
                <p style={{ maxWidth: props.maxDescriptionWidth }}>
                  {" "}
                  {props.description}{" "}
                </p>
              </span>
            )}

            {!!props.cta1Text && (
              <>
                {props.cta1Type === "link" && (
                  <Buttons
                    theme="primary  white"
                    arrow
                    link={`/${props.cta1Link}`}
                    text={props.cta1Text}
                  />
                )}
                {props.cta1Type !== "link" && (
                  <Buttons
                    theme="primary white"
                    arrow
                    onClick={props.cta1Link}
                    text={props.cta1Text}
                  />
                )}
              </>
            )}

            {!!props.cta2Text && (
              <>
                {props.cta2Type === "link" && (
                  <Buttons
                    theme="primary white"
                    arrow
                    link={`/${props.cta2Link}`}
                    text={props.cta2Text}
                  />
                )}
                {props.cta2Type !== "link" && (
                  <Buttons
                    theme="primary white"
                    arrow
                    onClick={props.cta2Link}
                    text={props.cta2Text}
                  />
                )}
              </>
            )}
          </Container>
        </div>
      )}
    </>
  )
}

export default CTABox
