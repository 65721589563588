import React from "react"

function PatternCTABoxLight() {
  return (
    <div className="pattern-cta-box">
      <svg
        width="78"
        height="206"
        viewBox="0 0 78 206"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="top-left position-absolute"
      >
        <circle
          cx="-111.171"
          cy="86.8938"
          r="162.185"
          transform="rotate(-8.22975 -111.171 86.8938)"
          stroke="#96D7D6"
          stroke-width="9"
        />
      </svg>

      <svg
        width="152"
        height="82"
        viewBox="0 0 152 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="top-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="-85.2493"
          r="162.185"
          stroke="#8DB1EA"
          stroke-width="9"
        />
      </svg>

      <svg
        width="200"
        height="83"
        viewBox="0 0 200 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="bottom-left position-absolute"
      >
        <circle
          cx="10.4193"
          cy="189.815"
          r="162.185"
          transform="rotate(-8.22975 10.4193 189.815)"
          stroke="#F9D24B"
          stroke-width="9"
        />
      </svg>

      <svg
        width="62"
        height="203"
        viewBox="0 0 62 203"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="bottom-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="166.751"
          r="162.185"
          stroke="#FDB8CD"
          stroke-width="9"
        />
      </svg>

      <svg
        width="161"
        height="95"
        viewBox="0 0 161 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-top-left position-absolute"
      >
        <circle
          cx="-5.68523"
          cy="-72.3148"
          r="162.185"
          transform="rotate(90 -5.68523 -72.3148)"
          stroke="#F9D24B"
          stroke-width="9"
        />
      </svg>

      <svg
        width="334"
        height="81"
        viewBox="0 0 334 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-top-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="-86.3148"
          r="162.185"
          transform="rotate(90 166.685 -86.3148)"
          stroke="#96D7D6"
          stroke-width="9"
        />
      </svg>

      <svg
        width="334"
        height="54"
        viewBox="0 0 334 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-bottom-left position-absolute"
      >
        <circle
          cx="166.685"
          cy="166.685"
          r="162.185"
          transform="rotate(90 166.685 166.685)"
          stroke="#8DB1EA"
          stroke-width="9"
        />
      </svg>

      <svg
        width="184"
        height="97"
        viewBox="0 0 184 97"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-bottom-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="166.685"
          r="162.185"
          transform="rotate(90 166.685 166.685)"
          stroke="#FDB8CD"
          stroke-width="9"
        />
      </svg>
    </div>
  )
}

export default PatternCTABoxLight
