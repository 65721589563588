import React from "react"

function PatternCTABoxDark() {
  return (
    <div className="pattern-cta-box">

      <svg
        width="87"
        height="225"
        viewBox="0 0 87 225"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="top-left position-absolute"
      >
        <circle
          cx="-80.3148"
          cy="57.6852"
          r="162.185"
          stroke="#96D7D6"
          stroke-width="9"
        />
      </svg>

      <svg
        width="188"
        height="154"
        viewBox="0 0 188 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="top-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="-13.3148"
          r="162.185"
          stroke="#8DB1EA"
          stroke-width="9"
        />
      </svg>

      <svg
        width="175"
        height="149"
        viewBox="0 0 175 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="bottom-left position-absolute"
      >
        <circle
          cx="7.68523"
          cy="166.685"
          r="162.185"
          stroke="#F9D24B"
          stroke-width="9"
        />
      </svg>

      <svg
        width="117"
        height="209"
        viewBox="0 0 117 209"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="bottom-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="166.685"
          r="162.185"
          stroke="#FDB8CD"
          stroke-width="9"
        />
      </svg>

      <svg
        width="161"
        height="95"
        viewBox="0 0 161 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-top-left position-absolute"
      >
        <circle
          cx="-5.68523"
          cy="-72.3148"
          r="162.185"
          transform="rotate(90 -5.68523 -72.3148)"
          stroke="#F9D24B"
          stroke-width="9"
        />
      </svg>

      <svg
        width="334"
        height="81"
        viewBox="0 0 334 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-top-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="-86.3148"
          r="162.185"
          transform="rotate(90 166.685 -86.3148)"
          stroke="#96D7D6"
          stroke-width="9"
        />
      </svg>

      <svg
        width="334"
        height="54"
        viewBox="0 0 334 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-bottom-left position-absolute"
      >
        <circle
          cx="166.685"
          cy="166.685"
          r="162.185"
          transform="rotate(90 166.685 166.685)"
          stroke="#8DB1EA"
          stroke-width="9"
        />
      </svg>

      <svg
        width="184"
        height="97"
        viewBox="0 0 184 97"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mob-bottom-right position-absolute"
      >
        <circle
          cx="166.685"
          cy="166.685"
          r="162.185"
          transform="rotate(90 166.685 166.685)"
          stroke="#FDB8CD"
          stroke-width="9"
        />
      </svg>

      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-top-left position-absolute"
      >
        <path
          d="M3.03226 16.9983C2.86051 16.8904 2.79627 16.6114 2.73203 16.3324C2.53931 15.4955 2.96934 14.8114 3.80711 14.6223L10.896 12.8752L5.22833 9.31216C4.71308 8.98825 4.5846 8.43028 4.62787 7.98028C4.67114 7.53029 4.99366 7.01725 5.55217 6.89116L15.7129 4.45061C16.5507 4.26149 17.2377 4.69337 17.4304 5.53032C17.6232 6.36727 17.1931 7.05132 16.3553 7.24045L9.37392 8.81652L15.0416 12.3795C15.5569 12.7035 15.6854 13.2614 15.6421 13.7114C15.4913 14.3324 15.2763 14.6745 14.7178 14.8005L4.44952 17.4121C3.89101 17.5382 3.37576 17.2143 3.03226 16.9983Z"
          fill="#CACBE6"
        />
      </svg>

      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-bottom-left position-absolute"
      >
        <path
          d="M18.1069 14.7459L4.92052 17.9779C4.10905 18.1799 3.50045 17.7759 3.29758 16.9679L0.0516976 3.83798C-0.15117 3.02998 0.254565 2.42399 1.06604 2.22199L9.99222 0C10.398 0 10.6008 7.67554e-07 11.0066 0.202L13.6438 1.61599L16.2811 3.02998C16.6868 3.23198 16.8897 3.43398 16.8897 3.83798L19.1213 13.1299C19.3241 13.7359 18.9184 14.5439 18.1069 14.7459ZM5.732 14.9479L16.2811 12.5239L14.4553 5.25197L12.4266 4.03998L10.398 3.02998L3.29758 4.44397L5.732 14.9479Z"
          fill="#96D7D6"
        />
      </svg>

      <svg
        width="25"
        height="23"
        viewBox="0 0 25 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-bottom-right position-absolute"
      >
        <path
          d="M2.2574 13.1191C1.91038 12.909 1.84388 12.6311 1.60387 12.2481C1.40436 11.4145 1.83238 10.7232 2.67391 10.52L8.56466 9.09772L7.2346 3.54005C7.03509 2.70639 7.46311 2.01517 8.30465 1.81198C9.14618 1.60879 9.84021 2.0291 10.0397 2.86275L11.7023 9.80984C11.9018 10.6435 11.4738 11.3347 10.6323 11.5379L3.33894 13.2989C2.88492 13.2615 2.4309 13.2242 2.2574 13.1191Z"
          fill="#F9D24B"
        />
        <path
          d="M16.1348 21.8265L9.60438 17.7506C8.91696 17.3216 8.71891 16.4698 9.14226 15.7631C9.56561 15.0565 10.3987 14.8483 11.0861 15.2773L17.6165 19.3531C18.3039 19.7822 18.502 20.634 18.0786 21.3406C17.6553 22.0473 16.8222 22.2555 16.1348 21.8265Z"
          fill="#F9D24B"
        />
      </svg>

      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-top-right position-absolute"
      >
        <path
          d="M3.65163 7.27196C5.66836 7.27196 7.30325 5.64407 7.30325 3.63598C7.30325 1.62788 5.66836 0 3.65163 0C1.63489 0 0 1.62788 0 3.63598C0 5.64407 1.63489 7.27196 3.65163 7.27196Z"
          fill="#CACBE6"
        />
      </svg>

    </div>
  )
}

export default PatternCTABoxDark
